import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

function Navbar() {

  const [clicked, setClicked] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  useEffect(()=>{
    const handleScroll=()=>{
      if(window.scrollY>window.innerHeight){
        setScrolled(true);
      }else{
        setScrolled(false);
      }
    };
    window.addEventListener('scroll',handleScroll);
    return()=>{
      window.removeEventListener('scroll',handleScroll);
    };
  },[]);

  const handleClick = () => {
    setClicked(!clicked)
  }

  return (
    <>

      <NavContainer scrolled={scrolled}>
        <div className={`links ${clicked ? 'active' : ''}`}>
          <a onClick={handleClick} href="/">Inicio</a>
          <a onClick={handleClick} href="Aboutus">Nosotros</a>
          <a onClick={handleClick} href="Services">Servicios</a>
          <a onClick={handleClick} href="Contact">Contacto</a>
        </div>

        <div className='burguer' onClick={handleClick}>
          <i className={`bi bi-list${clicked ? ' active' : ''}`} style={{ fontSize: '1.5em', color: scrolled ? '#333' : '#fff', padding:'3%'}}></i>
        </div>

        {/* Div para el fondo oscuro */}
        <BgDiv className={`bg ${clicked ? 'active' : ''}`} onClick={handleClick}></BgDiv>
        <DivIcon className={`DivIcon`} scrolled={scrolled}>
          <a href='https://api.whatsapp.com/send?phone=+5491134473752&text=Hola!%20me%20contacto%20de%20' target='_blank'><i className="bi bi-whatsapp"/></a>
          <a href='https://www.instagram.com/yunus.stud/' target='_blank'><i className="bi bi-instagram"/></a>
          <a href='mailto:info@yunus.com.ar' target='_blank'><i className="bi bi-envelope"/></a>
        </DivIcon>
      </NavContainer>

    </>
  )
}

export default Navbar

const NavContainer = styled.nav`
  padding: 0;
  position: fixed;
  width:100%;
  display:flex; 
  justify-content:space-between;
  align-items:center;
  background-color: ${({ scrolled }) => (scrolled ? '#fff' : 'transparent')};
  transition:0.5s;
  z-index: 999;

  .links {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.8s ease;
    transform: translateX(-100%);
    visibility: hidden;
    opacity: 0;

    a {
      display: block;
      color: #333;
      text-decoration: none;
      padding: 1em;
      font-family:sans-serif;
      font-size:1em;
      transition: transform 0.3s ease;
    }
    a:hover{
      transform: scale(1.1);
      color:#222;
    }
  }

  .links.active {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
  }

// BURGER

  .burguer {
    display: flex;
    flex-direction:row;
  }

  @media (max-width: 3200px) {
    .burguer {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }

    .links {
      position: absolute;
      top: 100%;
      left: 0px;
      width: 20%;
      height: 90vh;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: 900px) {
      .burguer {
        display: block;
      }
  
      .links {
        position: absolute;
        top: 100%;
        left: 0px;
        width: 70%;
        height: 90vh;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
  }
`

const BgDiv = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 0%;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
`

// ICON

const DivIcon = styled.div`
  padding:2%;
  display:flex;
  gap:0.7em;

  a {
  font-family:sans-serif;
  font-size:1em;
  color:${({scrolled})=>(scrolled ? '#333':'#fff')};
  }


  @media(max-width:900px){
    width:0%;
    a {
      font-size:0;
    }
  }
`
