import React, {useState, useEffect} from "react";

function App(){

    const [windowWidth, setWindowWith] = useState(window.innerWidth);

    useEffect(()=>{
        const handleResize =()=>{
            setWindowWith(window.innerWidth);
        };
        window.addEventListener('resize',handleResize);

        return()=>{
            window.removeEventListener('resize',handleResize);
        };
    },[]);

    return(
        <>
        <div style={{            
            width:'100%',
            height:'90vh',
            backgroundColor:'#000',
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            alignItems:'center',
            flexDirection:'column',
            gap:'3em'
        }}>
            
        <div style={{
            width:'100%',
            display:'flex',
            justifyContent:'space-around',
            alignContent:'center',
            alignItems:'center',
            flexDirection:windowWidth<1200?'column':'row',
            textAlign:windowWidth<1200?'center':'start',
            gap:'3em'
        }}>

            <div>
                <p style={{color:'#fff',fontSize:'1em',fontFamily:'sans-serif',textAlign:windowWidth<1200?'center':'center'}}>DÓNDE ESTAMOS</p>
                <p style={{color:'#fff',fontSize:'0.7em',fontFamily:'sans-serif'}}>Buenos Aires, Argentina.</p>

                <h2 style={{color:'#fff',fontSize:'1em',fontFamily:'sans-serif'}}>CONTÁCTANOS</h2>
                <p style={{color:'#fff',fontSize:'0.7em',fontFamily:'sans-serif'}}>
                    www.yunus.com.ar <br/>
                    info@yunus.com.ar <br/>
                    +54 9 11 3447 3752 <br/>
                </p>

            </div>

            <div style={{
                display:'flex',
                alignContent:'center',
                alignItems:'center',
                flexDirection:'column',
                gap:'1em'
            }}>
                <a href="https://www.instagram.com/yunus.stud/" target="_blank"><i className="bi bi-instagram" style={{fontSize:'1.5em',color:'#fff'}}/></a>
                <a href="https://api.whatsapp.com/send?phone=+5491134473752&text=Hola!%20me%20contacto%20de%20" target="_blank"><i className="bi bi-whatsapp" style={{fontSize:'1.5em',color:'#fff'}}/></a>
                <a href="mailto:info@yunus.com.ar" target="_blank"><i className="bi bi-envelope" style={{fontSize:'1.5em',color:'#fff'}}/></a>
            </div>

        </div>

            <h6 style={{color:'#fff',fontSize:'1em',fontFamily:'sans-serif'}}>Todos los derechos reservados 
            <a href="https://www.yunus.com.ar" style={{textDecoration:'none',color:'#fff',cursor:'pointer'}}> Yunus Studio</a> ®</h6>

        </div>    
        </>
    )
}
export default App