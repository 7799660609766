import React from "react";
import Spinner from 'react-bootstrap/Spinner'

const Loader = () => {
  return (
    <>
    <div style={{width:'100%',minHeight:'20vh',display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
    <Spinner animation="border" variant="success" />
    </div>
    </>
  );
};

export default Loader;
