import { useForm } from "../Hooks/useForm";
import Loader from "./Loader";
import Message from "./Message.js";

const initialForm = {
  name: "",
  num: "",
  email: "",
  subject: "",
  comments: "",
};

const validationsForm = (form) => {    
  let errors = {};
  let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  let regexNum = /^[0-9]+$/;
  let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
  let regexComments = /^.{1,255}$/;

  if (!form.name.trim()) {
    errors.name = "";
  } else if (!regexName.test(form.name.trim())) {
    errors.name = "El campo 'Nombre' sólo acepta letras y espacios en blanco";
  }

  if (!form.num.trim()) {
    errors.num = "";
  } else if (!regexNum.test(form.num.trim())) {
    errors.num = "El campo 'Telefono' sólo acepta números";
  }

  if (!form.email.trim()) {
    errors.email = "";
  } else if (!regexEmail.test(form.email.trim())) {
    errors.email = "El campo 'Email' es incorrecto";
  }

  if (!form.subject.trim()) {
    errors.subject = "";
  }

  if (!form.comments.trim()) {
    errors.comments = "";
  } else if (!regexComments.test(form.comments.trim())) {
    errors.comments =
      "El campo 'Comentarios' no debe exceder los 255 caracteres";
  }

  return errors;
};

let styles = {
  fontSize:'0.7em',
  fontWeight: "regular",
  color: "#ff0018",
};

const ContactForm = () => {
  const {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm(initialForm, validationsForm);

  return (
    <div>
      <form onSubmit={handleSubmit} style={{width:'100%', fontFamily:'sans-serif'}}>
        <label style={{fontFamily:'sans-serif',fontSize:'1em',color:'#fff'}}>Nombre</label>
        <input type="text" name="name" onBlur={handleBlur} onChange={handleChange} value={form.name} required
        style={{width:'100%',height:'5vh',backgroundColor:'#fff',border:'1px solid #e5e5e5', padding:'5px'}} />
        
        {errors.name && <p style={styles}>{errors.name}</p>}

        <label style={{fontFamily:'sans-serif',fontSize:'1em',color:'#fff'}}>Teléfono</label>
        <input type="num" name="num" onBlur={handleBlur} onChange={handleChange} value={form.num} required
        style={{width:'100%',height:'5vh',backgroundColor:'#fff',border:'1px solid #e5e5e5', padding:'5px'}} />
        
        {errors.num && <p style={styles}>{errors.num}</p>}

        <label style={{fontFamily:'sans-serif',fontSize:'1em',color:'#fff'}}>E-Mail</label>
        <input type="email" name="email" onBlur={handleBlur} onChange={handleChange} value={form.email} required
        style={{width:'100%',height:'5vh',backgroundColor:'#fff',border:'1px solid #e5e5e5', padding:'5px'}} />

        {errors.email && <p style={styles}>{errors.email}</p>}

        <label style={{fontFamily:'sans-serif',fontSize:'1em',color:'#fff'}}>Asunto</label>
        <input type="text" name="subject" onBlur={handleBlur} onChange={handleChange} value={form.subject} required
        style={{width:'100%',height:'5vh',backgroundColor:'#fff',border:'1px solid #e5e5e5', padding:'5px'}} />

        {errors.subject && <p style={styles}>{errors.subject}</p>}

        <label style={{fontFamily:'sans-serif',fontSize:'1em',color:'#fff'}}>Mensaje</label>
        <textarea name="comments" cols="50" rows="5" onBlur={handleBlur} onChange={handleChange} value={form.comments} required
        style={{width:'100%',height:'15vh',backgroundColor:'#fff',border:'1px solid #e5e5e5', padding:'5px'}} />

        {errors.comments && <p style={styles}>{errors.comments}</p>}

        <div style={{display:'flex',alignContent:'center',alignItems:'center',justifyContent:'center'}}>
          <input type="submit" value="Enviar" style={{
          backgroundColor:'#198754',
          fontFamily: 'title',
          fontSize: '1.1em',
          letterSpacing: '2px',
          color: '#fff',
          border: '0px',
          width: '50%',
          height: '7vh',
          borderRadius: '5px'
          }}/>
        </div>


      </form>

      {loading && <Loader />}
      <div style={{padding:'2%'}}>
        {response && (<Message msg="Los datos han sido enviados" bgColor="#198754" />)}
      </div>
    </div>
  );
};

export default ContactForm;
