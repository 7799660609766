import React from "react";
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Clientes from '../Components/Clients'
import Whatsapp from '../Components/Whatsapp'

function App(){
    return(
        <>
        <Navbar/>
        <div style={{
            width:'100%',
            height:'100vh',
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            alignItems:'center',
            flexDirection:'column'
        }}>
            <h2 style={{
                fontFamily:'Title',
                fontSize:'2em',
                color:'#333'
            }}>Módulo en reparación</h2>
            <a href="/">
                <button style={{
                    borderRadius:'25px',
                    border:'0px',
                    height:'7vh',
                    width:'25vh',
                    padding:'5%',
                    boxShadow:'0px 4px 16px 1px'
                }}>Volver al inicio</button>
            </a>
        </div>
        <Footer/>
        </>
    )
}
export default App