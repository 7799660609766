import React, {useEffect, useState} from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

import Services1 from '../Img/Services1.png'
import Services2 from '../Img/Services2.png'
import Services3 from '../Img/Services3.gif'
import Services4 from '../Img/Services4.png'


function App(){
const [windowWidth, setWindowWidth] = useState(window.innerWidth);

useEffect(() => {
const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
};
    
window.addEventListener('resize', handleWindowResize);

return () => {
    window.removeEventListener('resize', handleWindowResize);
};
}, []);

    return(
        <>
        <Navbar/>
        
        {/* WEB DESIGN */}
        <div style={{
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            alignItems:'center',
            flexDirection:windowWidth<1200?'column':'row'
        }}>
            <div style={{
                width:windowWidth<1200?'100%':'70%',
                height:'100%'
            }}>
                <img src={Services1} style={{width:'100%'}}/>
            </div>

            <div style={{
                width:windowWidth<1200?'100%':'30%',
                height:windowWidth<1200?'50vh':'',
                display:'flex',
                justifyContent:'center',
                alignContent:'center',
                alignItems:'flex-start',
                flexDirection:'column',
                padding:'2%'
            }}>

                <div data-aos="fade-right">
                    <h2 style={{
                        fontFamily:'Title',
                        fontSize:'2em',
                    }}>Soluciones Digitales.</h2>
                </div>
                <div data-aos="fade-right">
                    <p style={{
                        fontFamily:'Title',
                        fontSize:'1em'
                    }}>
                        Hacemos Desarrollo web, Tiendas online, Posicionamiento SEO, Landing page, Carta digital por QR, entre otros servicios.
                    </p>
                        <a href="Contact">
                            <button class="cta">
                                <span class="hover-underline-animation"> Contacto </span>
                                <svg
                                    id="arrow-horizontal"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="10"
                                    viewBox="0 0 46 16"
                                >
                                    <path
                                    id="Path_10"
                                    data-name="Path 10"
                                    d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                                    transform="translate(30)"
                                    ></path>
                                </svg>
                            </button>
                        </a>        
                </div>
            </div>
        </div>

        {/* CLOUD & HOSTING */}
        <div style={{
            width:'100%',
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            flexDirection:windowWidth<1200?'column-reverse':'row'
        }}>

            <div style={{
                width:windowWidth<1200?'100%':'30%',
                height:windowWidth<1200?'50vh':'',
                display:'flex',
                justifyContent:'center',
                alignContent:'flex-start',
                alignItems:'flex-start',
                flexDirection:'column',
                padding:'2%'
            }}>
                <div data-aos="fade-right">
                    <h2 style={{
                        fontFamily:'Title',
                        fontSize:'2em'
                    }}>Servicios Cloud Hosting & Chat Bot</h2>
                </div>
                <div data-aos="fade-right">
                    <p style={{
                        fontFamily:'Title',
                        fontSize:'1em'
                    }}>
                        Ofrecemos servicios de Cloud Hosting, E-mail con dominio, entre otros servicios en nube. 
                    </p>
                    <a href="Contact">
                        <button class="cta">
                            <span class="hover-underline-animation"> Contacto </span>
                            <svg
                                id="arrow-horizontal"
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="10"
                                viewBox="0 0 46 16"
                            >
                                <path
                                id="Path_10"
                                data-name="Path 10"
                                d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                                transform="translate(30)"
                                ></path>
                            </svg>
                        </button>
                    </a>        
                </div>
            </div>
        <div>
            <img src={Services2} style={{width:'100%'}}/>
        </div>
        </div>

        {/* POSICIONAMIENTO SEO */}
        <div style={{
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            alignItems:'center',
            flexDirection:windowWidth<1200?'column':'row'
        }}>
            <div style={{
                width:windowWidth<1200?'100%':'70%',
                height:'100%'
            }}>
                <img src={Services3} style={{width:'100%'}}/>
            </div>

            <div style={{
                width:windowWidth<1200?'100%':'30%',
                height:windowWidth<1200?'50vh':'',
                display:'flex',
                justifyContent:'center',
                alignContent:'flex-start',
                alignItems:'flex-start',
                flexDirection:'column',
                padding:'2%'
            }}>

                <div data-aos="fade-right">
                    <h2 style={{
                        fontFamily:'Title',
                        fontSize:'2em',
                    }}>Search engine optimization</h2>
                </div>
                <div data-aos="fade-right">
                    <p style={{
                        fontFamily:'Title',
                        fontSize:'1em'
                    }}>
                        Investigación de palabras y posicionamiento orgánico para que tu web sea una de las primeras. 
                    </p>
                        <a href="Contact">
                            <button class="cta">
                                <span class="hover-underline-animation"> Contacto </span>
                                <svg
                                    id="arrow-horizontal"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="10"
                                    viewBox="0 0 46 16"
                                >
                                    <path
                                    id="Path_10"
                                    data-name="Path 10"
                                    d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                                    transform="translate(30)"
                                    ></path>
                                </svg>
                            </button>
                        </a>        
                </div>
            </div>
        </div>

        {/* MARKETING DIGITAL */}
        <div style={{
            width:'100%',
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            flexDirection:windowWidth<1200?'column-reverse':'row'
        }}>

            <div style={{
                width:windowWidth<1200?'100%':'30%',
                height:windowWidth<1200?'50vh':'',
                display:'flex',
                justifyContent:'center',
                alignContent:'flex-start',
                alignItems:'flex-start',
                flexDirection:'column',
                padding:'2%'
            }}>
                <div data-aos="fade-right">
                    <h2 style={{
                        fontFamily:'Title',
                        fontSize:'2em'
                    }}>Marketing Digital</h2>
                </div>
                <div data-aos="fade-right">
                    <p style={{
                        fontFamily:'Title',
                        fontSize:'1em'
                    }}>
                        Diseño y gestión de redes sociales. <br/>
                        El conocimiento de tu comercio, basado en las redes. 
                    </p>
                    <a href="Contact">
                        <button class="cta">
                            <span class="hover-underline-animation"> Contacto </span>
                            <svg
                                id="arrow-horizontal"
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="10"
                                viewBox="0 0 46 16"
                            >
                                <path
                                id="Path_10"
                                data-name="Path 10"
                                d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                                transform="translate(30)"
                                ></path>
                            </svg>
                        </button>
                    </a>        
                </div>
            </div>
        <div>
            <img src={Services4} style={{width:'100%'}}/>
        </div>
        </div>
        <Footer/>
        </>
    )
}

export default App