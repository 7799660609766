import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import './App.css';
import AOS from 'aos'
import 'aos/dist/aos.css'
import Home from './Pages/Home'
import Services from './Pages/Services'
import Aboutus from './Pages/Aboutus'
import Contact from './Pages/Contact'

function App() {
  AOS.init({
    duration: 1000,
    });

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Aboutus' element={<Aboutus />} />
        <Route path='/Services' element={<Services />} />
        <Route path='/Contact' element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
