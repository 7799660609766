import React from "react";
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

import video from '../Img/home.mp4';

function App(){
    return(
        <>
        <Navbar/>

        <div style={{ position: 'relative', marginBottom: '100px' }}> {/* Contenedor del video */}
            <video autoPlay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
                <source src={video} type="video/mp4"/>
            </video>
        </div>
        
        <div style={{
            padding:'2%',
            width:'100%',
            height:'100vh'
        }}>
            <div data-aos="fade-right" style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignContent: 'center',
                alignItems: 'center',
                color: '#000',
                fontFamily: 'title',
                fontSize: '3.5em',
                padding: '0% 0% 0% 5%',
            }}>
                <h2>Impulsa tu negocio, <br/> con Yunus Studio.</h2>
            </div>

            <div data-aos="fade-right" style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignContent: 'center',
                alignItems: 'center',
                color: '#000',
                fontFamily: 'title',
                fontSize: '1.2em',
                padding: '5% 0% 0% 5%',
            }}>
                <p>Somos un estudio de soluciones digitales que brinda el mejor servicio y soporte, <br/>
                a la hora de desarrollar tu negocio digital.</p>
            </div>
            <div data-aos="fade-right" style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignContent: 'center',
                alignItems: 'center',
                color: '#000',
                fontFamily: 'title',
                fontSize: '1.2em',
                padding: '0% 0% 0% 5%',
            }}>
                <p>Nuestro objetivo es llevar tu marca a otro nivel a través de los medios digitales como redes sociales o websites.<br/>
                Nos agrada pensar que creamos vínculos entre tu comercio y tus clientes. </p>
            </div>
                    
            {/* BUTTON SERVICES */}

            <div style={{
                width:'100%',
                height:'20vh',
                display:'flex',
                justifyContent:'center',
                alignContent:'center',
                alignItems:'center'
            }}>

            <a href="Services">
                <button class="cta">
                    <span class="hover-underline-animation"> Servicios </span>
                    <svg
                        id="arrow-horizontal"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="10"
                        viewBox="0 0 46 16"
                    >
                        <path
                        id="Path_10"
                        data-name="Path 10"
                        d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                        transform="translate(30)"
                        ></path>
                    </svg>
                </button>
            </a>        

            </div>

        </div>



        <Footer/>

        </>

    )
}
export default App