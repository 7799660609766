import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import ContactForm from '../Components/ContactForm'
import 'bootstrap-icons/font/bootstrap-icons.css'
import Whatsapp from '../Components/Whatsapp'


function App(){
    return(
        <>
        <Navbar/>
        <div style={{
            width:'100%',
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            alignItems:'center',
            backgroundColor:'#000',
            padding:'10%'
        }}>

        <div style={{
            width:'50%',
            display:'center',
            alignItems:'center',
            alignContent:'center',
            justifyContent:'center',
        }}>
        <ContactForm/>            
        </div>
        </div>
        <Footer/>
        </>
    )
}
export default App